import React from 'react'
import Layout from '../components/Layout'

const Definition = ({ location }) => {
  return (
    <Layout location={location} lang={'en'}>
      <section id="one">
        <header className="major">
          <h2>Musical stimulation family workshops and sessions</h2>
          <h4>Ages 0 to 3</h4>
        </header>
        <p>
          The main source of communication during the first years of a child's life is basically the world of sound, which is why musical stimulation is a key tool for promoting their development and accompanying them in the process of discovering themselves and their environment, of their own sight and that of their peers, of their own body, of listening, of imitation and creation, of the discovery of instruments.
        </p>
        <p>
          During these weekly sessions and/or workshops, a space will be created to share and strengthen the bond between peers based on musical experiences. A global sensorial stimulation of the child will be carried out through songs, sounds, different materials, instruments, listening, movement, dances, skirt games, improvisation and relaxation.
        </p>
      </section>
      <section id="two">
        <h2>Gloals</h2>
        <p>
          <ul>
            <li>To enjoy the proposals presented as a family.</li>
            <li>To promote attention, concentration and memory.</li>
            <li>To explore the different instruments and materials presented and the sound they generate.</li>
            <li>Encourage hand-eye coordination.</li>
            <li>Encourage gross and fine motor skills.</li>
            <li>Stimulate the child's psychomotor skills.</li>
            <li>Synchronise movements to an external source (piece of music).</li>
            <li>Strengthen self-esteem, self-confidence and self-confidence.</li>
            <li>Strengthen the bond between family and child.</li>
            <li>Potentiate children's behavioural, social and communicative skills.</li>
          </ul>
        </p>
      </section>
      <section id="three">
        <h2>Upcoming Events</h2>
        <p>
          To check the upcoming music stimulation workshops,
          <a
            className="button special small"
            style={{ marginLeft: '15px' }}
            href='/events'
          >
            click here!
          </a>
        </p>
      </section>
    </Layout>
  )
};

export default Definition;
